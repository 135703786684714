import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

const Footer = ({ img }) => {
  const {
    site: {
      meta: { links },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      site {
        meta: siteMetadata {
          links {
            pinterest
            twitter
          }
        }
      }
    }
  `)

  return (
    <div className="container py-12 md:flex md:items-center md:justify-between">
      <div className="mt-8 place-items-center	justify-center flex items-center md:mt-0 md:order-1">
      {img && <Img
        fluid={img}
        alt="Tourism Australia Logo"
        className="w-24 md:w-32 mr-5 place-self-center"
      />}
        <p className="text-center text-sm text-gray-700">
          Created by <a className="font-semibold text-gray-900" rel="noopener noreferrer" href="https://spaceforalice.com" target="_blank">Space For Alice</a>
        </p>
      </div>
    </div>
  )
}

const FooterLink = ({ href, label, icon: Icon }) => {
  return (
    <li className="inline-block pl-6">
      <a
        href={href}
        target="_blank"
        rel="noreferrer noopener"
        className="text-gray-500 hover:text-blue-600 transition duration-150 ease-in-out"
      >
        <span className="sr-only">{label}</span>
        <Icon className="w-5 h-5 fill-current" />
      </a>
    </li>
  )
}

export default Footer
